.error-message {
  color: #FF0D0D;
  font-size: 12px;
  position: absolute;
  text-align: center !important;
  display: flex;
}
.err_block {
  display: block;
  text-align: center !important;
  left: 0;
  right: 0;
  margin: 0px auto;
}
