@import '/src/assets/Scss/_variables.scss';
@import '/src/assets/Scss/_mixins.scss';

.emicalculator {
    padding-top: 14.5%;
    padding-bottom: 10rem;
    color: #fff;
    display: flex;
    width: 1050px;
    flex-direction: column;
    margin: 0 auto;
    input {
        background: none;
        border:0;
        color:$ColorOrange;
        width: 200px;
        // &::before {
        //     content: '₹';
        //     color:#FC7124;
        // }
    }
    p{
        font-size: 20px;
        font-family: $BaseFontFamily;
        font-weight: 300;
    }
    span{
        font-size: 20px;
        font-family: $BaseFontFamily;
        font-weight: 700;
    }
    h1 {
        font-size: 5rem;
        font-weight: 800;
        color: #fff;
        text-align: center;
        margin-bottom: 5rem;
    }

    .form-field {
        margin-bottom: 4rem;
        display: flex;
        justify-content: space-between;position: relative;

        .emi_lbl {
            width: 200px;
            align-self: center;
            display: flex;

            label {
                font-size: $HeadingH4;
            }
        }

        .emi_amt {
            width: 65%;
            height: 50px;
            align-self: center;
            display: flex;
            position: relative;

            &.loan-amt:after {
                background: url('../../../assets/images/amt-range.png');
                content: '';
                width: 100%;
                left: 0;
                position: absolute;
                bottom: 0px;
                height: 30px;
            }

            &.loan-tenure:after {
                background: url('../../../assets/images/loan-tenure.png');
                content: '';
                width: 100%;
                left: 0;
                position: absolute;
                bottom: 0px;
                height: 30px;
            }

            &.roi:after {
                background: url('../../../assets/images/amt-range.png');
                content: '';
                width: 100%;
                left: 0;
                position: absolute;
                bottom: 0px;
                height: 30px;
            }
        }

        .emi_field {
            width: 220px;
            align-self: center;
            display: flex;
            border-bottom: 1px dotted $ColorOrange;
            color: $ColorOrange;
            font-size: $HeadingH4;
        }

        label {
            display: inline block;
        }

        .inputField {
            appearance: auto;
            cursor: default;
            color: #fff;
            padding: initial;
            border: initial;
            margin: 2px;
            width: 100%;
        }

        #tickmarks {
            .range {
                position: relative;

                ::before {
                    content: '';
                    height: 50px;
                    background: #fff;
                    width: 2px;
                }
            }
        }
        .btn-ok {padding:8px;position: absolute;right:-30px;background: #FC7124;color:#fff;border-radius: 5px;cursor: pointer;}
    }

    canvas {
        box-sizing: border-box;
        display: block;
        height: 300px;
        width: 300px;
        margin: auto;
    }

    .btn-primary {
        width: 300px;
        height: 50px;
        line-height: 40px
    }
}

.PieChart {
    width: 311px;
    margin: auto;
    @include position(relative);

    &::before {
        content: '';
        @include position(absolute);
        width: 250px;
        height: 250px;
        border-radius: 100%;
        background: $ColorWhite;
        top: 36%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
    }

    .innercircle {
        position: absolute;
        top: 37%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        color: #000;
        display: flex;
        flex-direction: column;
        width: 150px;
        align-items: center;

        span:nth-child(1) {
            font-size: 14px;
            font-weight: 800;
            color: $ColorPurple
        }

        span:nth-child(2) {
            font-size: $HeadingH5;
            font-weight: 800;
            color: $ColorPurple
        }
    }

    ul {
        margin-top: 3rem;
        li {
            list-style: none;
            position: relative;
            padding: 10px 5px;
            width: auto;
            display: flex;
            line-height: 20px;
            font-size: 20px;
            justify-content: space-between;
            font-family: $BaseFontFamily;
            font-weight: 300;
            &.ttl-interest {
                &::before {
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-radius: 50px;
                    background-color: rgba(237, 43, 144, 1);
                    position: absolute;
                    left: -20px;
                    top: 15px;
                }
            }

            &.principle-amt {
                &::before {
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-radius: 50px;
                    background-color: rgba(70, 132, 197, 1);
                    position: absolute;
                    left: -20px;
                    top: 15px;
                }
            }
        }
    }
}

.loan_dtls {
    h2 {
        font-family: $BaseFontFamily;
        font-size: 30px;
        text-align: center;
        margin-bottom: 3rem;
        font-weight: 400;
        span{
            font-size: 30px;
            font-weight: 400;
        }
    }
    padding-top:4rem;
    border-top:1px solid $ColorGray;
    margin-top:2rem;
    position: relative;

    &::before {
        content: '';
        border-top: 30px solid $ColorGray;
        border-right: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 30px solid transparent;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
    }

    &::after {
        content: '';
        border-top: 30px solid $ColorPurple;
        border-right: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 30px solid transparent;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -1px;
    }

    .emiAmt {
        color: $ColorOrange
    }
}