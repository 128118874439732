@import 'variables';
@mixin btnstyle {
    border:0;
    background: $ColorOrange;width:350px;height:60px;border-radius: 0;line-height: 50px;font-size: 20px;font-weight: 800;text-transform: uppercase;
}
// ----------------------- //
@mixin btnstyleHover {
    border:0;
    background: $ColorBlack;width:350px;height:60px;border-radius: 0;line-height: 50px;
}
// ----------------------- //
@mixin btnOutline {
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0em 1.4em;
    letter-spacing: 1px;
    font-family: $SecondFont;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 20px;
    background: $colorwhite;
    border:1px solid $maincolor;
    line-height: 38px;
    color:$maincolor;
}
// ----------------------- //
@mixin centerAlign {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}
// ----------------------- //
@mixin box-sizing($type) {
    -webkit-box-sizing:$type;
    -moz-box-sizing:$type;
    box-sizing:$type;
}
//Usage
//div {@include box-sizing(border-box);}
// ----------------------- //


@mixin opacity($opacity) {
    opacity: $opacity;
    filter: alpha(opacity=($opacity * 100));
}
//Usage
//div {@include opacity(0.5);}
// ----------------------- //

@mixin column-width ( $value: 150px ) {
   -webkit-column-width: $value;
   -moz-column-width: $value;
   column-width: $value;
}
//Usage
//div {@include column-width(150px);}
// ----------------------- //


@mixin circle
{
    @include border-radius(100%);
}
//Usage
//div {@include circle();}
// ----------------------- //


@mixin font-size($size) {
    font-size:$size;
    font-size: ($size / 16px) * 1rem;
}
//Usage
//div {@include font-size(14px);}
// ----------------------- //


@mixin box-shadow( $h: 10px , $v: 10px , $b: 0px , $s: 0px , $c: #000000 ) {
    -webkit-box-shadow: $h $v $b $s $c;
    -moz-box-shadow: $h $v $b $s $c;
    box-shadow: $h $v $b $s $c;
}
//Usage
//div {@include box-shadow(8px, 8px);}
// ----------------------- //


@mixin xPos($x) {
    -webkit-transform:translateX($x);
    -moz-transform:translateX($x);
    -ms-transform:translateX($x);
    transform:translateX($x);
}
//Usage
//div {@include xPos(50px);}
// ----------------------- //


@mixin vertical-align {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
//Usage
//div {@include vertical-align();}
// ----------------------- //


@mixin flexbox {
    display:-webkit-box; // old
    display:-moz-box; // old
    display:-ms-flexbox; // ie
    display:-webkit-flex; // new
    display:flex; // new
}
//Usage
//div {@include flexbox();}
// ----------------------- //  

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex:  $values;
    -ms-flex:  $values;
    -webkit-flex:  $values;
    flex:  $values;
}
//Usage
//div {@include flex(1, 2);}
// ----------------------- //


@mixin flex-order($order){
    -webkit-box-ordinal-group: $order; // old
    -moz-box-ordinal-group: $order; // old
    -ms-flex-order: $order; // ie
    -webkit-order: $order; // new
    order: $order; // new
}
//Usage
//div {@include flex-order(3);}
// ----------------------- //


@mixin flex-direction($direction)
 {
    @if $direction == column
    {
    -webkit-flex-direction:vertical;
    -moz-flex-direction:vertical;
    -ms-flex-direction:column;
    -webkit-flex-direction:column;
    flex-direction:column;
    }
    @else
    {
    -webkit-flex-direction:horizontal;
    -moz-flex-direction:horizontal;
    -ms-flex-direction:row;
    -webkit-flex-direction:row;
    flex-direction:row;
    }
 }
//Usage
//div {@include flex-direction(column);}
// ----------------------- //

 @mixin gradient($start-color, $end-color, $orientation)
 {
 background: $start-color;
 @if $orientation == vertical
    {
    // vertical
    background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
    }
 @else if $orientation == horizontal
    {
    // horizontal
    background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: -o-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: -ms-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: linear-gradient(to right,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    }
 @else
    {
    // radial
    background: -moz-radial-gradient(center, ellipse cover,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: -o-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: -ms-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    }
 }
//Usage
//div {@include gradient(#ff00ff, #ff00cc, vertical);}
// ----------------------- //



@mixin ghost-button($font, $font-size, $font-color, $border-size, $border-color, $padding, $transition-speed, $hover-color)
 {
    display:inline-block;
    text-decoration:none;
    text-transform:uppercase;
    font-family: $font;
    font-size: $font-size;
    color:$font-color;
    border:$border-size solid $border-color;
    padding:$padding;
    -webkit-transition: color $transition-speed, background $transition-speed;
    transition: color $transition-speed, background $transition-speed;
    &:hover
    {
    background:$border-color;
    color:$hover-color;
    }
 }
 //Usage
 //div {@include ghost-button(“Trebuchet”, 12px, #ffffff, 5px, #34dec6, 4px, 300ms, #000000 );}
// ----------------------- //



 @mixin break-point($point)
 {
    @if $point == desktop{
        @media only screen and (max-width:50em)
        {
            @content;
        }
        }
        @else if $point == mobile{
            @media only screen and (max-width:20em)
        {
            @content;
        }
    }
 }
 //Usage
 //div {margin:5em;@include break-point(mobile){margin:2em;}}

 @mixin border-radius($top-left:10px, $top-right:null, $bottom-right:null, $bottom-left:null){
    -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
       -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
        -ms-border-radius: $top-left $top-right $bottom-right $bottom-left;
            border-radius: $top-left $top-right $bottom-right $bottom-left;  
  }

  @mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }