
@import '../../../assets/Scss/variables';

.slick-slider {padding-bottom: 3rem;
    .slick-list {padding-top: 4rem;
        .slick-track {
            .slick-slide {
                .cards {width:310px;overflow:hidden;position: relative;height:432px;
                    figure {position: relative;height:350px;
                        &::after {content:'';background: rgb(0,0,0);background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,0.21332282913165268) 90%);position: absolute;bottom: 0;left: 0;width:100%;height:50px}
                        figcaption {background: none;text-align: left;left:0;bottom:0;transform: none;z-index: 99;color: $ColorWhite;}
                    }
                    figcaption {display: flex;flex-direction: column;background: $ColorWhite;padding:10px;text-align: left;
                        h5 {font-size: $HeadingH5;}
                        .btn {width: 70px;height:30px;border:1px solid $ColorOrange;color:$ColorOrange;border-radius: 0;line-height: 17px;text-transform:uppercase;padding:0;font-size:$HeadingH6;}
                        
                    }
                    .hover_dtls {display: none;transform: translateY(100%);transition:0.5s all ease-in-out;position: absolute;top:0;left:0;z-index: 99;width: 100%;height: 100%;}    
                    &:hover {
                        .hover_dtls {background: $ColorOrange;width: 100%;height: 100%;z-index: 9999;display: flex;
                            justify-content: center;align-items: center;transform:translateY(0%);
                            ul {margin:0 auto;padding: 0;list-style-type: none;
                                li {width:150px;height:36px;display:flex;flex-direction:column;margin:2px;color:$ColorWhite;
                                    a {background: $ColorWhite;text-align: center;color: $ColorOrange;text-decoration: none;line-height: 36px;font-weight: 600;
                                        &:hover {background: $ColorPurple;color:$ColorWhite}
                                    }
                                    &:nth-child(2) {margin-top: 0;}
                                    +li {margin-top: 1rem;}
                                }
                            }
                        }
                    }
                    
                }
   
            }
        }
    }
    .slick-arrow {position: absolute;bottom: 0;top:inherit;width: 20%;}
}
.slick-list {
    margin: 0 -10px !important;
  }
  
  .slick-slide > div {
    padding: 0 10px !important;
  }