:root {
  --bs-body-bg: #160041;
}

.slick-slider {
  padding-bottom: 3rem;
}
.slick-slider .slick-list {
  padding-top: 4rem;
}
.slick-slider .slick-list .slick-track .slick-slide .cards {
  width: 310px;
  overflow: hidden;
  position: relative;
  height: 432px;
}
.slick-slider .slick-list .slick-track .slick-slide .cards figure {
  position: relative;
  height: 350px;
}
.slick-slider .slick-list .slick-track .slick-slide .cards figure::after {
  content: "";
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.2133228291) 90%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
}
.slick-slider .slick-list .slick-track .slick-slide .cards figure figcaption {
  background: none;
  text-align: left;
  left: 0;
  bottom: 0;
  transform: none;
  z-index: 99;
  color: #fff;
}
.slick-slider .slick-list .slick-track .slick-slide .cards figcaption {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px;
  text-align: left;
}
.slick-slider .slick-list .slick-track .slick-slide .cards figcaption h5 {
  font-size: 1.25rem;
}
.slick-slider .slick-list .slick-track .slick-slide .cards figcaption .btn {
  width: 70px;
  height: 30px;
  border: 1px solid #FC7124;
  color: #FC7124;
  border-radius: 0;
  line-height: 17px;
  text-transform: uppercase;
  padding: 0;
  font-size: 0.875rem;
}
.slick-slider .slick-list .slick-track .slick-slide .cards .hover_dtls {
  display: none;
  transform: translateY(100%);
  transition: 0.5s all ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
}
.slick-slider .slick-list .slick-track .slick-slide .cards:hover .hover_dtls {
  background: #FC7124;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0%);
}
.slick-slider .slick-list .slick-track .slick-slide .cards:hover .hover_dtls ul {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}
.slick-slider .slick-list .slick-track .slick-slide .cards:hover .hover_dtls ul li {
  width: 150px;
  height: 36px;
  display: flex;
  flex-direction: column;
  margin: 2px;
  color: #fff;
}
.slick-slider .slick-list .slick-track .slick-slide .cards:hover .hover_dtls ul li a {
  background: #fff;
  text-align: center;
  color: #FC7124;
  text-decoration: none;
  line-height: 36px;
  font-weight: 600;
}
.slick-slider .slick-list .slick-track .slick-slide .cards:hover .hover_dtls ul li a:hover {
  background: #160041;
  color: #fff;
}
.slick-slider .slick-list .slick-track .slick-slide .cards:hover .hover_dtls ul li:nth-child(2) {
  margin-top: 0;
}
.slick-slider .slick-list .slick-track .slick-slide .cards:hover .hover_dtls ul li + li {
  margin-top: 1rem;
}
.slick-slider .slick-arrow {
  position: absolute;
  bottom: 0;
  top: inherit;
  width: 20%;
}

.slick-list {
  margin: 0 -10px !important;
}

.slick-slide > div {
  padding: 0 10px !important;
}/*# sourceMappingURL=project_slider.css.map */