:root {
  --bs-body-bg: #160041;
}

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 620px;
  height: 48rem;
  margin: 0 auto;
  color: #ffffff;
}
.register .container .captions h2 {
  font-size: 2.5rem;
  line-height: 35px;
  color: #FC7124;
  font-weight: 700;
  text-align: left;
}
.register .container .captions p {
  font-size: 1.5rem;
  line-height: 40px;
  color: #ffffff;
  font-weight: 500;
  text-align: left;
  margin-bottom: 2rem;
}

form .row {
  margin: 30px -10px;
}
form .row .form-group {
  margin: 0;
  padding: 0 10px;
}
form .row .form-group input {
  height: 50px;
  border-radius: 0;
  color: #000;
}
form .row .form-group select {
  width: 100%;
  height: 50px;
}
form .row button[type=submit] {
  border: 0;
  background: #FC7124;
  width: 100%;
  height: 50px;
  border-radius: 0;
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: #ffffff;
}
form .row button[type=submit]:hover {
  background: #050505;
  color: #fff;
}
form .row .progress {
  height: 22px;
  padding: 0;
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 10px;
  --bs-progress-box-shadow: inset 0 1px 2pxrgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #fd0d9e;
  --bs-progress-bar-transition: width 0.6s ease;
}

.verification {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 950px;
  padding: 0;
  height: 470px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.verification h2 {
  font-size: 2.5rem;
  line-height: 35px;
  color: #FC7124;
  font-weight: 700;
  text-align: left;
}
.verification p {
  font-size: 1.875rem;
  line-height: 40px;
  color: #ffffff;
  font-weight: 500;
  text-align: left;
  margin-bottom: 2rem;
}
.verification form .form-group {
  width: 100%;
}
.verification form .form-group input {
  width: 25%;
  text-align: center;
  border-right: 1px solid #D1D1D1;
  font-size: 18px;
  line-height: 30px;
  height: 50px;
  border-top: 0;
  border-left: 0;
}
.verification form .form-group button {
  border: 0;
  background: #FC7124;
  width: 100%;
  height: 50px;
  border-radius: 0;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: #ffffff;
}
.verification form .form-group button:hover {
  background: #050505;
  color: #fff;
}
.verification form .form-group .progress {
  height: 22px;
  padding: 0;
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 10px;
  --bs-progress-box-shadow: inset 0 1px 2pxrgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #fd0d9e;
  --bs-progress-bar-transition: width 0.6s ease;
}
.verification form .resendOTP {
  text-transform: uppercase;
  margin: 1rem 0;
  font-weight: 700;
}

.progress {
  overflow: visible;
  position: relative;
  height: 22px;
  border-radius: 15px;
}
.progress .progress-bar {
  position: relative;
  overflow: visible;
}
.progress .progress-bar::before {
  content: "";
  background: rgb(9, 138, 252);
  background: linear-gradient(90deg, rgb(9, 138, 252) 0%, rgb(143, 20, 252) 27%, rgb(170, 4, 201) 44%, rgb(205, 10, 162) 59%, rgb(253, 79, 68) 73%, rgb(251, 128, 18) 92%);
  border-radius: 10px;
  width: 18px;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 18px;
  display: flex;
  z-index: 99;
}
.progress span {
  background: #FC7124;
  position: absolute;
  top: 35px;
  padding: 3px 20px;
  border-radius: 10px;
  font-size: 0.875rem;
  font-weight: 700;
  color: #fff;
  left: -15px;
}
.progress span::before {
  content: "";
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #FC7124;
  border-left: 10px solid transparent;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.verification-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 100%;
  padding: 2rem;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 50px 7px RGB(255 255 255/46%);
  z-index: 99;
  background: rgba(22, 0, 65, 0.9294117647);
  text-align: left;
}
.verification-modal h2 {
  font-size: 2.5rem;
  line-height: 60px;
  color: #FC7124;
  font-weight: 700;
  text-align: left;
}
.verification-modal p {
  font-size: 1.875rem;
  line-height: 40px;
  color: #ffffff;
  font-weight: 500;
  text-align: left;
}
.verification-modal .over-layout {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  width: 900px;
  padding: 2rem;
  height: 470px;
  box-shadow: 0px 0px 50px 7px RGB(255 255 255/46%);
  z-index: 99;
  background: #160041;
  position: relative;
}
.verification-modal form .form-group input {
  width: 25%;
  text-align: center;
  border-right: 1px solid #D1D1D1;
  font-size: 18px;
  line-height: 30px;
  height: 50px;
  border-top: 0;
  border-left: 0;
}
.verification-modal .resendOTP {
  text-transform: uppercase;
  margin: 1rem 0;
  font-weight: 700;
}
.verification-modal .modal-close {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 10px;
}
.verification-modal .modal-close:hover {
  cursor: pointer;
}

.register .form {
  max-width: 100%;
}
.register .radio-btn {
  position: relative;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.register .radio-btn label {
  font-size: 16px;
  display: flex;
  font-weight: 300;
  line-height: 30px;
}
.register .radio-btn input {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.register .radio-btn .btn-plan {
  margin-left: 0px;
  width: 100%;
  position: absolute;
  left: 22px;
}
.register .radio-btn .selector {
  margin-left: 4rem;
}
.register .row .progress {
  overflow: visible;
  position: relative;
}
.register .row .progress .progress-bar {
  position: relative;
  overflow: visible;
}
.register .row .progress span {
  background: #FC7124;
  position: absolute;
  top: 35px;
  padding: 3px 20px;
  border-radius: 10px;
  font-size: 0.875rem;
  font-weight: 700;
}
.register .row .progress span::before {
  content: "";
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #FC7124;
  border-left: 10px solid transparent;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.register .progress {
  margin-top: 0rem;
  height: 22px;
  padding: 0;
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 10px;
  --bs-progress-box-shadow: inset 0 1px 2pxrgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #fd0d9e;
  --bs-progress-bar-transition: width 0.6s ease;
}
.register .next-button {
  display: flex;
  justify-content: center;
}/*# sourceMappingURL=register.css.map */