@import '/src/assets/Scss/_variables.scss';
@import '/src/assets/Scss/_mixins.scss';

.applicant{
    padding-top: 10rem;
    text-align: center;
    padding-bottom: 5rem;
    .container{
        h2{ font-size: $HeadingH3; font-family: $BaseFontFamily; text-align: center; line-height: 53px;margin-bottom: 3rem;}
        p{ font-size: $HeadingH4; font-family:$BaseFontFamily; text-align: center ; line-height: 40px; color: $ColorWhite;margin-bottom: 3rem}
    }
    
    .radio-btn{
        @include position(relative); @include flexbox(); justify-content: space-between; align-items: center; color: $ColorWhite;
        label{font-size: $BaseFont;@include flexbox();font-weight: 300;line-height: 30px;}
        input{ width: 25px; height: 25px; margin-right: 10px; }
        .btn-plan{ margin-left: 0px;width: 100%;@include position(absolute);left: 22px; }
        .form-check-input[type=radio] {@include border-radius(100%);border-color: $ColorOrange;border-width: 2px;}
        .form-check-input:checked {background-color: $ColorOrange;@include position(relative);
            &::before {content:'';width: 15px;height:15px;background: $ColorWhite;border-radius: 100%;@include position(absolute);top:50%;left:50%;transform: translate(-50%,-50%);margin-top:-1px;}
        }
        .form-check-input:checked[type=radio] {background-image: none;}
    }
    .form-check-input[type=checkbox] {
        border-radius: 0;width: 25px;height: 25px; border-color: $ColorOrange;
    }
    .form-check-input:checked {background-color: $ColorOrange;}
    .form-check-label{ font-size: $HeadingH6; line-height: 22px; font-family:$BaseFontFamily; text-align: left; color: $ColorWhite;}

    .bank-span{ font-size: $HeadingH6; line-height: 30px; color: $ColorWhite; text-align: left; font-weight: bold; font-family:$BaseFontFamily;@include flexbox();}
    hr{border-top: 2px solid gray;}
    .file-bank{ @include flexbox(); background-color: $ColorWhite;
    }
    ul{ display: block;list-style: none; color: $ColorWhite; text-align: left;padding-left: 0;
        li{font-size: $HeadingH6; font-weight: 300; margin: 5px 0; padding: 0;padding-left:1rem; @include position(relative);
            &:before{background: $ColorWhite;@include border-radius(100%);content: "";height: 10px;left: 0;
                @include vertical-align();
                width: 10px;
            }
        }
    }
    .save-button{@include flexbox(); justify-content: center; text-align: center;}
    .verify{ @include position(relative); @include flexbox();
        .adhar-verify{font-size: $BaseFont;font-weight: bold; color: $ColorOrange; width: 100px; border: 1px solid $ColorOrange;}
        .formMargin { width: 100%; }
    }
    .co-applicant{form { .row {
        span{ font-size: $BaseFont; font-weight: bold; line-height: 30px; font-family:$BaseFontFamily; color: $ColorWhite; text-align: left;}
    }}}
    .minus{ @include position(absolute); color: $ColorOrange; font-size: 22px; right: -8%; width: 25px;}
    .plus{ @include position(absolute); color: $ColorOrange; font-size: 22px; right: -6%; margin-top: -80px; }
}
.cust-cal {@include position(relative);
    .icon_cal {@include position(absolute);
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    
    }
}
form .row {
    .cust-uploadfile {@include position(relative);background:$ColorWhite;
        label {@include position(absolute);top:50%;color:$ColorOrange;font-weight: 800;left: 50%;text-transform: uppercase;transform: translate(-50%,-50%);width: 100%;
            svg {@include position(absolute);top:50%;left:10%;transform: translateY(-50%);}
        }
        input {
            &[type='file']{color: transparent;opacity: 0;}
        }
    }
}