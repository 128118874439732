@import 'variables';

.register{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 100vh;
    margin:0 auto;
    color: #ffffff;
    .container {
        .captions{
            h2{font-size: $HeadingH3;line-height: 35px;color: #FC7124;font-weight: 700;text-align: left;}
            p{font-size: 1.5rem;line-height: 40px;color: #ffffff;font-weight: 500;text-align: left;margin-bottom: 2rem;}
        }
        
    }
}

form .row{
    margin: 30px -10px;
    .form-group{ margin: 0; padding: 0 10px;
        input{height: 50px;border-radius: 0;
            color: #000;
        }
        select{ width: 100%; height: 50px;}
    }
    button[type=submit]{border: 0;background: #FC7124;width: 100%;height: 50px;border-radius: 0;
        font-size: 20px;font-weight: 800;text-transform: uppercase; color: #ffffff;
        &:hover {background: $ColorBlack;color:$ColorWhite}
    }
    .progress{
        height: 22px;
        padding: 0;
        --bs-progress-height: 1rem;
        --bs-progress-font-size: 0.75rem;
        --bs-progress-bg: #e9ecef;
        --bs-progress-border-radius: 10px;
        --bs-progress-box-shadow: inset 0 1px 2pxrgba(0, 0, 0, 0.075);
        --bs-progress-bar-color: #fff;
        --bs-progress-bar-bg: #fd0d9e;
        --bs-progress-bar-transition: width 0.6s ease;
    }
}
.verification{display: flex;justify-content: center; align-items: center;color: #ffffff; width: 950px;
    padding: 0; height: 470px; position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);
    h2{font-size:$HeadingH3;line-height: 35px;color: #FC7124;font-weight: 700;text-align: left;}
    p{font-size: $HeadingH4;line-height: 40px;color: #ffffff;font-weight: 500;text-align: left;margin-bottom: 2rem;}
    form{
        .form-group{
            width: 100%;
            input{ width: 25%; text-align: center; border-right: 1px solid #D1D1D1; font-size: 18px; line-height: 30px; height: 50px;border-top: 0;border-left: 0; }
            button{border: 0;background: #FC7124;width: 100%;height: 50px;border-radius: 0; margin-top: 25px;font-size: 20px;font-weight: 800;text-transform: uppercase; color: #ffffff;
                &:hover {background: #050505;color: #fff;}
            }
            .progress{
                height: 22px;
                padding: 0;
                --bs-progress-height: 1rem;
                --bs-progress-font-size: 0.75rem;
                --bs-progress-bg: #e9ecef;
                --bs-progress-border-radius: 10px;
                --bs-progress-box-shadow: inset 0 1px 2pxrgba(0, 0, 0, 0.075);
                --bs-progress-bar-color: #fff;
                --bs-progress-bar-bg: #fd0d9e;
                --bs-progress-bar-transition: width 0.6s ease;
                
            }
        }
        .resendOTP {text-transform: uppercase;margin:1rem 0;font-weight: 700;}
    }
    
}
.progress{overflow: visible;position: relative;height:22px;border-radius:15px;
    .progress-bar {position: relative;overflow: visible;
        &::before {content: '';background: rgb(9,138,252);
            background: linear-gradient(90deg, rgba(9,138,252,1) 0%, rgba(143,20,252,1) 27%, rgba(170,4,201,1) 44%, rgba(205,10,162,1) 59%, rgba(253,79,68,1) 73%, rgba(251,128,18,1) 92%);border-radius: 10px;    width:18px;
            position: absolute;
            top: 2px;
            left:2px;
            height: 18px;
            display: flex;
            z-index: 99;}
    }
    span {background: $ColorOrange;position: absolute;top:35px;padding:3px 20px;border-radius: 10px;font-size:$HeadingH6;font-weight:700;color:$ColorWhite;left:-15px;
        &::before {content:'';border-top:10px solid transparent;border-right: 10px solid transparent;border-bottom: 10px solid $ColorOrange; border-left: 10px solid transparent;position: absolute;top:-20px;left:50%;transform: translateX(-50%);}
    }
}

.verification-modal{
    h2{font-size:$HeadingH3;line-height: 60px;color: #FC7124;font-weight: 700;text-align: left;}
    p{font-size: $HeadingH4;line-height: 40px;color: #ffffff;font-weight: 500;text-align: left;}
    display: flex;justify-content: center;align-items: center;color: #ffffff;width: 100%;padding: 2rem;height: 100%;position: absolute;
    top: 50%;left: 50%;transform: translate(-50%, -50%);box-shadow: 0px 0px 50px 7px RGB(255 255 255 / 46%);z-index: 99;
    background: #160041ed;    text-align: left;
    .over-layout{display: flex;justify-content: center;flex-direction: column;align-items: center;color: #ffffff;
        width: 900px;padding: 2rem;height: 470px; box-shadow: 0px 0px 50px 7px RGB(255 255 255 / 46%);z-index: 99;
        background: #160041;position: relative;
    }

    form .form-group input {width: 25%;text-align: center;border-right: 1px solid #D1D1D1;font-size: 18px;
        line-height: 30px;height: 50px;border-top: 0;border-left: 0;    }
    .resendOTP {text-transform: uppercase;margin:1rem 0;font-weight: 700;}
    .modal-close{display: block;position: absolute;right: 0;top: 0;margin-right: 10px;
        &:hover{ cursor: pointer;}
    }
}

.register {
    .form {
        max-width: 100%; 
    }
    .radio-btn{
        position: relative; display: flex; align-items: center; color: #ffffff;
        label{font-size: 16px;display: flex;font-weight: 300;line-height: 30px;}
        input{ width: 25px; height: 25px; margin-right: 10px; }
        .btn-plan{ margin-left: 0px;width: 100%;position: absolute;left: 22px; }
        .selector{margin-left: 4rem;}
    }
    .row .progress{overflow: visible;position: relative;
        .progress-bar {position: relative;overflow: visible;}
        span {background: $ColorOrange;position: absolute;top:35px;padding:3px 20px;border-radius: 10px;font-size:$HeadingH6;font-weight:700;
            &::before {content:'';border-top:10px solid transparent;border-right: 10px solid transparent;border-bottom: 10px solid $ColorOrange; border-left: 10px solid transparent;position: absolute;top:-20px;left:50%;transform: translateX(-50%);}
        }
    }
    .progress{
        margin-top: 0rem;height: 22px;padding: 0;
        --bs-progress-height: 1rem;
        --bs-progress-font-size: 0.75rem;
        --bs-progress-bg: #e9ecef;
        --bs-progress-border-radius: 10px;
        --bs-progress-box-shadow: inset 0 1px 2pxrgba(0, 0, 0, 0.075);
        --bs-progress-bar-color: #fff;
        --bs-progress-bar-bg: #fd0d9e;
        --bs-progress-bar-transition: width 0.6s ease;
        
    }
    .next-button{display: flex; justify-content: center;    }
}