@import '/src/assets/Scss/_variables.scss';
@import '/src/assets/Scss/_mixins.scss';


.project{
    .radio-btn{
        @include position(relative); @include flexbox(); align-items: center; color: $ColorWhite;
        label{font-size: 16px;@include flexbox();font-weight: 300;line-height: 30px;}
        input{ width: 25px; height: 25px; margin-right: 10px; }
        .btn-plan{ margin-left: 0px;width: 100%; @include position(absolute);left: 22px; }
        .form-check-input[type=radio] {border-radius: 50%;border-color: $ColorOrange;border-width: 2px;}
        .form-check-input:checked {background-color: $ColorOrange;}
        
        //.form-check-input:checked[type=radio] {background-image: none;}
    }
    .form-check-input[type=checkbox] {
        border-radius: 0;width: 25px;height: 25px; border-color: $ColorOrange;
    }
    .form-check-input:checked {background-color: $ColorOrange;}
    .form-check-label{ font-size:$HeadingH6; line-height: 22px; font-family: $BaseFontFamily; text-align: left; color: $ColorWhite;}
    .form{
        .row{
            .save-button{ @include flexbox(); justify-content: center; width: 100%;
                // button[type=submit] {   
                //     width: 75%;
                // }
            }
        }
    }
}
.wrapper{@include flexbox();width: 170px;margin: 0 auto;    
    .counter{@include flexbox();color: $ColorWhite;background-color: $ColorOrange;width: 90px;height: 80px;
        justify-content: center;align-items: center;font-size: 60px; line-height: 80px; font-family: $BaseFontFamily; font-weight: bold;
    }
    .colon{padding: 10px;color: $ColorWhite;font-size: $HeadingH4;}
}