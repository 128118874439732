@media screen and (max-width: 1366px){
    .applicant { padding: 9rem 0 3rem;
        .container .contact_sec h2 { margin-bottom: 1rem; }
     }
}

@media screen and (max-width: 768px) {
    section figure figcaption {
        transform: translate(-50%, -45%);
        width: 75%;
        h2{ font-size: 3rem; }
        h3 span{ font-size: 100px; }
    }
    .creditScore{ height: 700px; display: block;
        figure {display: flex;justify-content: center;padding:25px 0;span {left: auto;}
        }
     figure figcaption {top: 360px;text-align: center;}
    }
    .instantHL figure figcaption{ 
        text-align: center;
        h2 { width: 100%; text-align: center;  }
        h5 { justify-content: center; margin: 2rem 0; }
    }
    .about_sec {padding: 2rem 1rem;
        h2{ padding: 1rem 0; }
        p{padding-right: 0px;}
    }
    .downPayment-sec .finace_sec {
        height: 100%;padding: 7rem 0;
    }
    .whiteBg ul { padding: 0;  flex-wrap: wrap;
        li{ width: 50%; margin-bottom: 2rem;
            span { font-size: 1.69rem;}
            .whiteBg ul li .icon {
                width: 70px;
                height: auto;
                margin: 0 auto;
            }
        }
    }
    .approval_steps{width: 100%; 
        li { width: 230px; height: 190px;
            .dsc { padding: 0 1rem;margin-top: 0.5rem; }
        }
    }
    .row .row-md{
        div{margin-top: 2rem;}
    }
    .register .form {margin: 0 auto; }
    .crdscore{ padding: 2rem 0; }
    .creditscore-main .credit-inner .credit-title h3{font-size: 70px;}
    footer .footer .footer_links .foot_links .copyright span { text-align: left; }
    footer .footer .footer_logos figure { display: none; }
    footer .footer .footer_links { width: 100%; }
    .navbar-collapse{ flex-grow: 0; }
    .cust_sec { height: 380px; margin: 1rem 0; padding-left: 380px;
        figure{ height: 300px; width: 360px; }
    }
    .bullet-list li { padding-left: 85px;padding-right: 0;height: 70px; 
        span { width: 70px;height: 70px; }
        label {font-size: 19px;line-height: 25px;}
    }
    .AboutSec { padding: 7rem 2rem 2rem; form { width: 550px; }
        p { font-size: 30px;line-height: 40px; }
    }
    .applicant { padding: 7rem 0 3rem;
       .container .contact_sec h2 { margin-bottom: 1rem; }
    }
    .verification { width: 100%; }
}

@media screen and (max-width: 576px) {
    header{
        .topNav {
            .hamburger-react{color: #fff; display: block;}
            .navbar-toggler {display: flex;align-items: center; border-radius: 0; 
                span {
                    &+span {text-transform: uppercase;font-size: 15px;align-self: center;font-weight: bold;font-family: 'Avenir';
                    text-align: left;padding-left: 15px;}
                }
            }
            .navbar-toggler{ &:focus{box-shadow: none; }
                span{font-size: 10px;color: #FFF;font-weight: 500;text-transform: uppercase;line-height: 0;&.line{
                    height: 4px;width: 35px;border-radius: 50px;display: block;margin-top: 6px;background-color: #FFF;
                    transition: all 0.5s ease; }
                    .line{ &:first-child{ margin-top:0} }
                    &.m-line{height: 4px;width: 35px;border-radius: 50px;display: block;margin-top: 6px;background-color: #FFF;transition: all 0.5s ease;}
                }
                &.open-toggler{span{
                        &.line:nth-child(1){-webkit-transform: rotate(45deg);-moz-transform: rotate(45deg);-ms-transform: rotate(45deg);
                            transform: rotate(45deg);transition: all 0.5s ease;
                        }
                        &.line:nth-child(3){-webkit-transform: rotate(-45deg);-moz-transform: rotate(-45deg);-ms-transform: rotate(-45deg);
                            transform: rotate(-45deg);margin-top: -4px;transition: all 0.5s ease;
                        }
                        &.m-line{display: none;opacity: 0;}
                    }
                }
            }
        }
    }

    .navLinks{ display: none;}
    .creditScore figure span {
        width: 350px;
    }
    .heroBanner {
        width: 100%;
        height: 60vh;
        figure{ figcaption { 
            h3{ font-size: 22px; 
                span{ font-size: 55px; }
            }
        }}
    }
    section figure figcaption {
        width: 100%;
        h2{ font-size: 2rem;}
    }
    .btn-primary { width: 300px;height: 60px;line-height: 50px;font-size: 25px; }
    .instantHL { height: 60vh;   
        figure figcaption { 
            h2 { width: 100%; } 
            h5 { margin: 1.5rem 0; }
        }
    }
    .crdscore { padding: 0; }
    .creditScore { height: 500px; 
        figure figcaption { top: 210px; }
    }
    .about_sec h2 { padding: 0; font-size: 2rem; }
    .about_sec p {font-size: 1rem;}
    footer .footer{ flex-direction: column;
    .footer_logos { 
        figure{ display: inline-block; }
        span img{ display: none;}
        }
        .footer_links{ 
            width: 100%;
            ul li  {width: 100%; font-size: 18px; }
            .socialMedia{ padding-left: 0;  justify-content: flex-start;}
            .copyright { display: block; }
        }
    }
    footer .footer .footer_links .foot_links { display: block; }
    .approval_steps li { width: 125px;height: 140px; margin: 0 5px; }
    .approval_steps li .lbl-txt { font-size: 18px; margin-top: 2rem; }
    .approval_steps li .dsc { font-size: 18px;font-weight: 500;padding: 0 3px;margin-top: 0px;line-height: 24px; }
    .whiteBg ul li span { font-size: 18px;line-height: 25px; }
    .register{ width: 100%; }
    .error-message { font-size: 10px; }
    .cust_sec { display: block; flex-direction: column; height: 100%; padding: 0;
        figure { height: 100%;width: 380px; position: relative; margin: 1rem auto; }
    }
    .AboutSec{ padding: 7rem 0 2rem; p {font-size: 24px;line-height: 30px; padding: 0 0.5rem; }
    form { width: 100%;
        .row { margin: 0; 
            .form-group {padding: 10px 10px;} 
            }
        }
        h2 { font-size: 3rem;font-weight: 700; }
        .btn-primary { width: 100%;}
    }
    .imageBdr {
        border: 6px solid #4684C5;
    }
    .AboutSec .error-message {margin-top:0px;}
    .AboutSec .btn-primary svg {left: 0.5rem; }
    .contact_sec .form{ margin: 0;
        .row .form-group { padding: 10px 10px; }
    }
    .applicant {
        .verify .adhar-verify {
        width: 200px;
        }    
        .contact_sec{
            form .row  {margin: 0px; }
        }
        .container{ 
            p {line-height: 30px; margin-bottom: 15px;;}
            h2 { font-size: 2.2rem; }
        }
        .radio-btn { margin: 15px 0; display: flex; flex-wrap: wrap; } 
    }
    .project{
        .container{ 
                form .row {
                margin: 0;
                .form-group{ padding: 10px 10px;}
                
            }
            .captions{ 
                h2 {text-align: center;font-size: 2.2rem; }
                p {text-align: center; line-height: 30px;margin-bottom: 15px;}
            }
        }
    }
    .slick-slider .slick-list .slick-track .slick-slide .cards {
        width: 100%;
    }
    .contact_sec{
        h1{ font-size: 3rem;}
        form .row { margin: 0; }
        .contactDetails{ display: block;}
    }
    .creditscore-main .credit-inner {
        padding: 7rem 0 3rem;
        .credit-title h3 {
            font-size: 45px;
        }
        .credit-img{ form .row { 
            margin: 0; .form-group { padding: 10px 10px; }
            }
            figure{ img { width: 370px; }}
            span {margin-bottom: 15px;}     
        }
    }
    // .crdscore{ padding: 7rem 0 3rem; }
    .verification { top: 35%; 
        .form {
            width: 370px;
            margin: 0 auto;
        }
        .captions{ h2 {text-align: center;}
            p{text-align: center; font-size: 1.69rem;}
        }
    }
    .register{
        height: 44rem;
        .formRe{padding: 10px 0;}
        form{
            .row {
                margin: 0;
                .continue{ padding: 10px 10px;}
            }
            .pross{ padding-top: 1.8rem; }
            width: 100%;
        }
        .container .captions{
            h2{ text-align: center;}
            p{ text-align: center; margin-bottom: 1rem; }
        }
    }
    .innerSec { padding-top: 7rem; 
        h4 { font-size: 1.69rem; margin: 1rem 0; }
    }
    .faq_sec { width: 100%; }
    .faq_sec .accordion-item h2 .accordion-button::after { right: 14px; height: 20px; top: 6px; }
    .faq_sec .accordion-item h2 .accordion-button::before { right: 14px; height: 20px; top: 6px;}
    .faq_sec .accordion-item.active h2 .accordion-button::before{ right: 14px; height: 20px; top: 14px; }
    .faq_sec .accordion-item.active h2 .accordion-button::after{ right: 14px; height: 20px; top: -6px; }
    .accordion-button{padding: 9px;}
    .approvedloanoffer .container { width: 100%; }
    .inner-pg { padding-top: 7rem; padding-bottom: 3rem; }
    .approvedloanoffer .container .tbl-style td{ padding: 10px 20px; }
    .provision_letter .whiteBg .container { width: 100%; }
    .emicalculator {width: 100%; padding-bottom: 4rem;
            h1 {font-size: 2rem;padding: 3rem 0 0rem; margin-bottom: 3rem; }
            .form-field { margin-bottom: 2rem;display: block;width: 100%; 
            .emi_amt { width: 100%; }
            .emi_lbl{ width: 100%;
                label { font-size: 25px; }
            }
            .emi_field { width: 100%; }
        }
        .loan_dtls h2 {
            margin-bottom: 1rem;
            font-size: 20px;
        }
    }

    .openmenu{display: block; position: absolute;top: 4.6rem;width: 100%;left: 0;height: 92.2vh;background-color: #ff7c42e8;
        ul{ padding-left: 0;
            li{ list-style: none;width: 100%; border-bottom: 1px solid #fff;
                a{ line-height: 50px;font-size: 24px;text-decoration: none;color: #fff; padding-left: 1rem; font-weight: 500; }
                ul{ display: none; }
                &:hover{
                    // background-color: #fff;
                    a{color: #ff7c42e8;}
                    ul{ display: flex; flex-direction: column; position: relative; background-color: #ff7c42e8;
                        li{ list-style: none;width: 100%; border-bottom: 1px solid #fff;padding: 0.5rem 2rem;
                            a{ line-height: 40px;font-size: 24px;text-decoration: none;color: #fff; padding-left: 3rem; font-weight: 500; }
                            &:last-child { border-bottom: none; }
                            &:hover{
                                background-color: #fff;
                                a{color: #ff7c42e8;}
                            }
                           span {padding-left: 0;}
                        }
                    }
                }
                &.homebuyer {
                    span { line-height: 40px;font-size: 24px;text-decoration: none;color: #fff; padding-left: 1rem; font-weight: 500; }
                }
            }
        }
    }

    .downPayment-sec .finace_sec { padding: 7rem 0 3rem; }
    .downPayment-sec .finace_sec .agreement-value {
        .agreement-bifurcation { margin-top: -75px;flex-direction: column;align-items: center;
            li{ margin-bottom: 5rem;}
            li.purple{ &:before {
                content: '';height: 29px;position: absolute;top: -47px;left: 56px;border-right: 2px solid #a608a6;background: none; width: 0; }
            }
            li.orange { &:before {top: -53px; height: 27%; left: 56px;}}
            li.blue{ &:before {
                content: '';height: 34px;position: absolute;top: -52px;left: 56px;border-right: 2px solid #448aa3 ;background: none; width: 0; }
            }
        }
        .agreement-value-dtls { 
            &:after {bottom: 45%; left: 45%; }
            &:before {height: 28px;bottom: 137px; left: 46.5%;}
        }
    }
    .downPayment-sec .finace_sec .agreement-value{ padding-bottom: 1rem; margin-top: 5rem; }
    .whiteBg{ padding: 3rem 0 1rem;
        ul { padding: 0;  flex-wrap: wrap;
            li{ width: 50%;
                span { font-size: 1.69rem;}
                .whiteBg ul li .icon {
                    width: 70px;
                    height: auto;
                    margin: 0 auto;
                }
            }
        }
    }
    .approval_steps {margin: 4rem auto 2rem;}
    .form-check { margin-bottom: 1rem; }

    header .topNav .logo figure img {width: 150px;height: auto;}
    .about_sec {height: 60vh;min-height: auto;}
    footer .footer .footer_logos {display: flex;justify-content: space-between;width: 100%;margin:0.5rem 0}
    footer .footer .footer_links ul li {width: auto;}
    footer .footer .footer_links ul li + li {padding-left: 12px;}
    footer .footer .footer_links {padding: 1rem 0;}
    footer {height: auto;}
    footer .footer .footer_links ul {justify-content: space-between;}
    footer .footer .footer_links ul li {font-size: 15px;}
    footer .footer .footer_links .foot_links .copyright span a {text-decoration: none;}
    footer .footer .footer_links .foot_links .copyright {flex-direction: column;text-align: center;margin-top: 1rem;    }
    section figure figcaption {transform: translate(-50%, -50%);top:50%;bottom:inherit}
    .btn-primary {
        width: auto;
        height: 50px;
        line-height: 40px;
        font-size: 20px;
    }
    .error-message {margin-top: -10px;}
    .openmenu {background-color: rgb(19, 14, 46);
        padding: 10px;
        width: 100%;
        left: 0;
        transform: translateX(20%);
        // left: inherit;
        transition: .5s all ease-in-out;
        position: fixed;}
    footer .footer .footer_logos .foot-img {height: 50px;}
    footer .footer .footer_logos .foot-img img {display: inline-block;}
    footer .footer .footer_logos figure {margin:0;width: 260px;
        img {width: 100%;height: auto;}
    }
    .desktop-purpose {display: none;}
    .mobile-purpose {display: inline-block;}
}