// @font-face {
//     font-family: 'hk-grotesk';
//     src: url('../fonts/HKGrotesk-Bold.woff2') format('woff2'),
//         url('../fonts/HKGrotesk-Bold.woff') format('woff');
//     font-style: normal;
// }


:root {
    --bs-body-bg: #160041;
}

$BaseFontFamily:'hk-grotesk';
$SecondFontFamily:'Avenir';
$BaseFont:1rem; //16px
$HeadingH2:5rem; //80px
$HeadingH3:2.5rem; //40px
$HeadingH4:1.875rem; //30px
$HeadingH5:1.25rem; //20px
$HeadingH6:0.875rem; //14px
$ColorWhite:#fff;
$ColorOrange:#FC7124;
$ColorPurple:#160041;
$ColorLightPurple:#8D4D9E;
$ColorBlack:#050505;
$ColorGray:#707070;
$ColorBlue:#1085FC;
$ColorLightBlue:#4684C5;
$ColorPink:#F91B7A;