@import '../../../assets/Scss/variables';
@import '../../../assets/Scss/mixins';

.creditscore-main{
    .credit-inner{
        padding: 150px 0;
        .credit-title{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            & h2{
                font-size: 40px;
                font-family: $BaseFontFamily;
                font-weight: 800;
                color: #FC7124;
            }
            & h3{
                font-size: 80px;
                font-family: $BaseFontFamily;
                font-weight: 800;
                color: #FFFFFF;
                & .free{
                    color: #FC7124;
                }
            }
        }
        .credit-img{
            padding-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            & figure{
            }
            & span{
                color: #FFFFFF;
                font-size: 20px;
                font-weight:bold;
                line-height: 20px;
            }
        }
    }
}
