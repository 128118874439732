@import 'variables';
@import 'mixins';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'HK-Grotesk';
    src: url('../fonts/HKGrotesk-Bold.woff2') format('woff2'),
        url('../fonts/HKGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('../fonts/HKGrotesk-Black.woff2') format('woff2'),
        url('../fonts/HKGrotesk-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('../fonts/HKGrotesk-BoldItalic.woff2') format('woff2'),
        url('../fonts/HKGrotesk-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Legacy';
    src: url('../fonts/HKGrotesk-BoldLegacy.woff2') format('woff2'),
        url('../fonts/HKGrotesk-BoldLegacy.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Legacy';
    src: url('../fonts/HKGrotesk-BoldLegacyItalic.woff2') format('woff2'),
        url('../fonts/HKGrotesk-BoldLegacyItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $BaseFontFamily;
    src: url('../fonts/HKGrotesk-Italic.woff2') format('woff2'),
        url('../fonts/HKGrotesk-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Legacy';
    src: url('../fonts/HKGrotesk-LegacyItalic.woff2') format('woff2'),
        url('../fonts/HKGrotesk-LegacyItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $BaseFontFamily;
    src: url('../fonts/HKGrotesk-ExtraBold.woff2') format('woff2'),
        url('../fonts/HKGrotesk-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $BaseFontFamily;
    src: url('../fonts/HKGrotesk-Medium.woff2') format('woff2'),
        url('../fonts/HKGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Light Legacy';
    src: url('../fonts/HKGrotesk-LightLegacyItalic.woff2') format('woff2'),
        url('../fonts/HKGrotesk-LightLegacyItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $BaseFontFamily;
    src: url('../fonts/HKGrotesk-LightItalic.woff2') format('woff2'),
        url('../fonts/HKGrotesk-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Light Legacy';
    src: url('../fonts/HKGrotesk-LightLegacy.woff2') format('woff2'),
        url('../fonts/HKGrotesk-LightLegacy.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $BaseFontFamily;
    src: url('../fonts/HKGrotesk-Light.woff2') format('woff2'),
        url('../fonts/HKGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Medium Legacy';
    src: url('../fonts/HKGrotesk-MediumLegacyItalic.woff2') format('woff2'),
        url('../fonts/HKGrotesk-MediumLegacyItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $BaseFontFamily;
    src: url('../fonts/HKGrotesk-Regular.woff2') format('woff2'),
        url('../fonts/HKGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Legacy';
    src: url('../fonts/HKGrotesk-RegularLegacy.woff2') format('woff2'),
        url('../fonts/HKGrotesk-RegularLegacy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $BaseFontFamily;
    src: url('../fonts/HKGrotesk-MediumItalic.woff2') format('woff2'),
        url('../fonts/HKGrotesk-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Medium Legacy';
    src: url('../fonts/HKGrotesk-MediumLegacy.woff2') format('woff2'),
        url('../fonts/HKGrotesk-MediumLegacy.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk SmBold Legacy';
    src: url('../fonts/HKGrotesk-SemiBoldLegacyItalic.woff2') format('woff2'),
        url('../fonts/HKGrotesk-SemiBoldLegacyItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $BaseFontFamily;
    src: url('../fonts/HKGrotesk-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/HKGrotesk-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $BaseFontFamily;
    src: url('../fonts/HKGrotesk-SemiBold.woff2') format('woff2'),
        url('../fonts/HKGrotesk-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk SemiBold Legacy';
    src: url('../fonts/HKGrotesk-SemiBoldLegacy.woff2') format('woff2'),
        url('../fonts/HKGrotesk-SemiBoldLegacy.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}



body {background-color:var( --bs-body-bg);font-family: $BaseFontFamily;font-size: 16px;}
.container {max-width: 1325px;}
.btn-primary {@include btnstyle;}
.btn-primary:hover {@include btnstyleHover;}

.openmenu{display: none;}
header {position: absolute;width: 100%;z-index: 99;
    .topNav {padding:20px 0;@include flexbox();flex-direction: row;justify-content: space-between;align-items: center;
        .logo {
            figure {margin:0;
                .logo {width: 270px;display:inline-block;
                    img {width: 100%;height: auto;}
                }
            }
        }
        .hamburger-react{color: #fff; display: none;}
    }
}
.navLinks {@include flexbox();flex-direction: row;margin:0;padding:0;list-style-type: none;@include position(relative);
    li {text-align: center;@include position(relative);
        span {
            color: #fc7124;    font-size: 18px;
            font-weight: 500;
            padding: 24px 0;
            text-decoration: none;cursor: pointer;
        }
        a {color:$ColorWhite;text-decoration: none;font-size: 18px;font-weight: 500;padding: 24px 0;
            &:hover {color:$ColorOrange;}
        }
        &+li {margin-left: 4rem;@include position(relative);}
        .dropdown {display: none;@include position(absolute);top:50px;left: 50%;transform: translateX(-50%)}
        &:hover {
            & .dropdown {display: inline-block;width: 300px;background: $ColorWhite;padding:0;margin:0;list-style-type: none;border-radius: 10px;
            @include position(absolute);top:50px;left: 50%;transform: translateX(-50%);
            li {padding:1rem 2rem;text-align:left;
                a {color:$ColorOrange}
                &+li {margin-left:0;border-top:1px solid #ddd;}
                &:hover {
                    span {color:$ColorPurple}
                }
                &:first-child {border-top-left-radius: 8px;border-top-right-radius: 8px;}
                &:last-child {border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;}
            }
                &:before {content:'';border-top:10px solid transparent;border-right:10px solid transparent;border-bottom:10px solid $ColorWhite;border-left:10px solid transparent;@include position(absolute);top:-18px; left:50%;transform: translateX(-50%);}
            }
        }
        &.homebuyer {
            > span {color:$ColorWhite;}
        }
    }
}
section {
    figure {margin:0;@include position(relative);height: 100%;
        img {width:100%;height: 100%;object-fit: cover;}
        figcaption {text-align: center;width: 90%;
            @include position(absolute);z-index: 9;bottom:0%;left:50%;transform: translate(-50%,-12%);
            h2 {font-size: $HeadingH2;font-weight: 800;color:$ColorWhite;text-align: center;
                span {display: block;}
            }
            h3 {font-size: $HeadingH3;font-weight: 800;color:$ColorWhite;text-align: center;display:flex;align-items: center;justify-content:center;
                span {font-size: 120px;color:$ColorOrange;padding:0 20px;text-shadow: 0px 2px 6px #000;}
                a {padding-left: 1rem;font-size: $HeadingH5;text-decoration: underline;color:$ColorWhite}
            }
            h5 {font-size: $HeadingH5;font-weight: 800;color:$ColorWhite;text-align: center;display:flex;align-items: center;justify-content:center;justify-content: flex-start;margin:3rem 0}
            .btn {margin: 0 auto;}
        }
    }
}


.heroBanner {width:100%;height:100vh;
    
}
.instantHL {@include position(relative);
    figure {
        figcaption {@include position(absolute, $top: 50%, $left: 55%, $bottom:inherit);transform: translate(-55%,-50%); z-index: 9;font-size: 50px;color:$ColorWhite;width: 100%;text-align: left;
            h2 {text-align: left;width: 40%;}
        }
    }
}
footer {height: 110px;
    .footer {
        @include flexbox();justify-content: space-between;
        .footer_logos {width: 200px;@include flexbox();align-items: center;
            .foot-img {height:80px;
                img {height: 100%;}
            }
            figure {margin-right: 10%;}
        }
        .footer_links {
            width: auto;padding:2rem 0;
            @include flexbox();
            flex-direction: column;
            justify-content: space-between;

            ul {margin:0;padding: 0;list-style-type: none; @include flexbox(); flex-direction: row; justify-content: flex-end; flex-wrap: wrap;
                li {width: auto;
                    a {color: $ColorWhite;text-decoration: none;}
                    +li {padding-left:50px;}
                }
            }
            .foot_links {
                @include flexbox();flex-direction: row;justify-content: space-between;
                .copyright {@include flexbox();flex-direction: row;
                    span {color:$ColorWhite;text-align: right;font-size: 14px;
                        a {color:#fff;font-weight: 600;}
                    }
                }
                ul {margin:0;padding:0;width:200px;
                    li {width:auto;@include position(relative);font-size: 14px;
                        &+li {padding-left: 20px;
                            &:before {content:'|';@include position(absolute);left:10px;color:$ColorWhite}
                        }
                    }
                    
                }
            }
            .socialMedia {justify-content: flex-end;
                li {width: auto; font-size: 22px;
                    +li {padding-left:5px}
                }
            }
        }
    }
}
.creditScore {
    background: #160041; height:768px; @include flexbox();align-items: center;
    figure {@include flexbox();padding-left: 700px;
       
        span {width:610px;display:inline-block;position:absolute;left:0;
            img {width: 100%;}
        }
        figcaption {@include position(relative);transform:none;left:0;text-align:left;
            h2 {text-align: left;
                span {@include position(relative);display: inline-block;width: auto;color: $ColorOrange;}
            }
            h4 {margin:1rem 0 2rem;color:$ColorWhite;}
        }
    }
}
.about_sec {background: #8D4D9E;min-height: 969px;padding:4rem 0;position: relative;
    h2 {font-size: $HeadingH2;font-weight: 800;color:$ColorWhite;text-align: left;padding:3rem 0;
        span {display: inline-block;}
    }
    p {font-size: $HeadingH5;font-weight: 500;color:$ColorWhite;padding-right: 70px;}
    figcaption {
        width: 100%;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
.downloadTxt {padding:2rem 0 2rem 3rem;@include position(relative);display:flex;color:$ColorWhite;font-size:16px;font-weight:600;text-decoration:none;text-transform: uppercase;
    svg {@include position(absolute);left:0;font-size: 35px;margin-top: -5px;}
    &:hover {color: $ColorOrange;}
}
.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: $ColorPurple;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
}
.downPayment-sec {height:100%;
    >figure {@include position(absolute);width: 100%;z-index: -9;margin:0;height:807px;
        img {width: 100%;height: 100%;}
    }
    h3 {font-size: $HeadingH3;color:$ColorOrange;text-align: center;font-weight: 700;margin-bottom: 2rem;}
    h4 {font-size: $HeadingH4;color:$ColorOrange;font-weight: 800;}
    .finace_sec {height:800px;@include position(relative);padding-top:7rem;
        ul {
            li {color:$ColorWhite;font-size: $HeadingH5;font-weight: 500;text-align:justify;
                &+li {margin-top:1rem}
            }
        }
        .agreement-value {margin-top:0rem;max-width:570px;padding-bottom:6rem;margin:0 auto;
            .agreement-value-dtls {margin: 0 auto;width:100%;text-align:center;height: 100px;width:300px;
                padding-bottom: 17rem;@include position(relative);
                &::before {content:'';border-left: 2px solid $ColorWhite;@include position(absolute);left: 50%;height: 80px;bottom: 85px;margin-left: 1px;}
                &::after {content:'';width:15px;height: 15px;border-radius: 100%;@include position(absolute);bottom: 30%;left: 48%;z-index: 9;
                    background: $ColorWhite;}
                span {display: inline-block;height: 100px;width:300px;background: $ColorWhite;border-radius: 50px;margin: 0 auto;font-size: $HeadingH5;text-align: center;@include position(relative);font-weight:700;line-height:30px;padding-top:1rem;
                    label {display: block;font-weight: 700;font-size: $HeadingH4;}
                    &::before {content:'';background:url('../images/price-left-bg.png');@include position(absolute);left:-14px;top:-12px;width: 162px;height: 124px;}            
                    &::after {content:'';background:url('../images/price-right-bg.png');@include position(absolute);right:-14px;top:-10px;width: 166px;height: 120px;}
                }
            }
            .agreement-bifurcation {margin:0;padding:0;list-style-type: none;@include flexbox();justify-content:space-between;
                li {margin:0;@include position(relative);
                    span {width: 130px;height:130px;border-radius: 100%;background: $ColorWhite;@include flexbox();@include position(relative);
                        label {@include position(absolute);top:50%;left:50%;transform: translate(-50%,-50%);color:#000;width: 90%;text-align: center;color:$ColorWhite;font-weight: 700;
                            small {display: block;}
                        }
                    }
                    & +li {margin-top:0;}
                    &.purple {
                        &::before{content:'';background:url('../images/purple-bdrline.png');width: 220px;height: 75px;@include position(absolute);top: -94px;left: 60px;}
                        span {background: #AC00DB;
                            &::before {content:'';background:url('../images/purple-before.png');width: 93px;height: 185px;@include position(absolute);top: -26px;left: -30px;}
                            &::after {content:'';background: url('../images/purple-after.png');width: 92px;height: 180px;@include position(absolute);top: -23px;right: -24px;}
                        }
                    }
                    &.orange {
                        &::before{content:'';border-left: 2px solid #f04b4f;@include position(absolute);left: 50%;top: -90px;height: 52%;}
                        span {background: #F04B4F;
                            &::before {content:'';background:url('../images/orange-before.png');width: 93px;height: 185px;@include position(absolute);top: -26px;left: -30px;}
                            &::after {content:'';background: url('../images/orange-after.png');width: 92px;height: 180px;@include position(absolute);top: -23px;right: -24px;}
                        }
                    }
                    &.blue {
                        &::before{content:'';background:url('../images/bluebdrline.png') no-repeat;width: 220px;height: 75px;@include position(absolute);top: -94px;left: -154px;}
                        span {background: $ColorLightBlue;
                            &::before {content:'';background:url('../images/blue-before.png');width: 93px;height: 185px;@include position(absolute);top: -26px;left: -30px;}
                            &::after {content:'';background: url('../images/blue-after.png');width: 92px;height: 180px;@include position(absolute);top: -23px;right: -24px;}
                        }
                    }
                }
            }
        }
        h2 {font-size: $HeadingH2;color:$ColorWhite;text-align: center;font-weight: 800;margin-bottom: 4rem;}
    }
}
.whiteBg {background: $ColorWhite;padding:3rem 0;
    ul {margin: 0;
        padding: 0 10rem;
        list-style-type: none;
        @include flexbox();
        justify-content: space-between;
        width: 100%;
        align-items: center;
        li {text-align: center;
            span {text-align: center;font-size: $HeadingH4;line-height: 35px;font-weight: 200;}
            .icon {@include flexbox();justify-content: center;margin-bottom: 1rem;}
            b {@include flexbox();justify-content: center;font-weight: 500;}
        }
    }
}
.approval_sec {background: $ColorLightPurple;padding:3rem 0;
    h3 {color:$ColorWhite}
}
.approval_steps {margin:2rem auto;padding:0;list-style-type: none;display:flex;width: 1000px;justify-content:space-between;
    li {width: 300px;@include flexbox();flex-direction: column;justify-content: center;align-content: center;text-align: center;
        border-radius: 30px;margin-top:2rem;@include position(relative);height:200px;
        .icon {width:90px;height:90px;border-radius: 100%;@include position(absolute);top:0%;left:50%;transform:translate(-50%,-50%);border:8px solid $ColorLightPurple;
            img {width:auto;height:auto;@include position(absolute);top:50%;left:50%;transform:translate(-50%,-50%)}
        }
        &.stepOne {background: $ColorBlue;
            .icon {background: $ColorBlue;}
        }
        &.stepTwo {background: $ColorPink;
            .icon {background: $ColorPink;}
        }
        &.stepThree {background: $ColorLightBlue;
            .icon {background: $ColorLightBlue;}
        }
        .lbl-txt {font-size: $HeadingH4;font-weight: 800;color:$ColorWhite;margin-top:1rem;}
        .dsc {font-size: $HeadingH4;font-weight: 500;color:$ColorWhite;padding:0 3rem;line-height: 35px;margin-top:1rem;}
    }
}
.form {max-width: 600px;
    .form-contrrol {width: 100%;}
    textarea {border:0;height:100px;resize: none;padding:10px}
    .btn-sm {width: 300px;height: 50px;margin: 0 auto;}
}
.AboutSec {padding-top:12rem;max-width:1000px; margin:0 auto 3rem;
    h2 {font-size: $HeadingH2;font-weight: 800;color:$ColorWhite;text-align: center;}
    h3 {font-size: $HeadingH3;font-weight: 800;color:$ColorOrange;text-align: center;}
    p {color:$ColorWhite;font-size: $HeadingH4;}
    .btn-primary {width:455px;margin:2rem auto;@include flexbox(); justify-content: center;@include position(relative); text-align: center;font-size:$BaseFont;font-weight:500;
        svg {@include position(absolute);left:2rem;top:50%;font-size: 35px;transform: translateY(-50%);}
    }
    .btn-block {width:300px;margin:0 auto;}
    .form {margin:0 auto}
}
.imageBdr {border:15px solid $ColorLightBlue}
.bullet-list {margin:0;padding:0;list-style-type: none;
    li {@include position(relative);padding-left:100px;padding-right:20px;height:80px;border:1px solid $ColorLightBlue;border-radius:100px;margin-bottom:2rem;display:flex;align-items:center;
        span {width: 80px;height:80px;border-radius: 100%;background: $ColorWhite;display: inline-block;@include position(absolute);left:0;box-shadow:0 0 20px rgba(255, 255, 255, 0.50);
            img {@include position(absolute);top:50%;left:50%;transform: translate(-50%,-50%);}
        }
        label {font-size: $HeadingH5;color:$ColorWhite;font-weight: 700;}
    }
}
.cust_sec {height: 380px;margin:5rem 0;
    @include position(relative);padding-left: 570px;@include flexbox();align-items: center;flex-direction:row;
    figure {@include position(absolute);left:0;height:380px;width: 540px;}
    
}
.innerSec {padding-top:12rem;max-width:1000px; margin:0 auto 3rem;
    h2 {font-size: $HeadingH2;font-weight: 800;color:$ColorWhite;text-align: center;}
    h3 {font-size: $HeadingH3;font-weight: 800;color:$ColorOrange;text-align: center;}
    h5 {font-size: $HeadingH4;color:$ColorWhite;text-align: center;}
    h4 {font-size: $HeadingH3;color:$ColorWhite;text-align: center;margin:2rem 0;}
    p {color:$ColorWhite;font-size: $HeadingH4;text-align: center;}
    .btn-primary {width:455px;margin:2rem auto;@include flexbox(); justify-content: center;@include position(relative); text-align: center;font-size:$BaseFont;font-weight:500;
        svg {@include position(absolute);left:2rem;top:50%;font-size: 35px;transform: translateY(-50%);}
    }
    .btn-block {width:300px;height:50px;margin:0 auto;background: $ColorOrange;color:$ColorWhite;border-radius: 0;font-size: $HeadingH5;text-transform: uppercase;font-weight: 700;}
    .form {margin:0 auto}
}

.faq_sec {padding:6rem 0;width: 1000px;margin:0 auto;
    .accordion-item {border:1px solid $ColorOrange;background: none;margin-bottom:2rem;color:$ColorOrange;border-radius:0;
        h2 {
            .accordion-button {background: none;color:$ColorOrange;@include position(relative);font-size:$HeadingH5;font-weight:700;border-bottom: 0;
                &::before {content:'';right:30px;@include position(absolute);border:0;border-right: 1px solid $ColorOrange;transform: rotate(-90deg);height: 30px;top:14px;}
                &::after {content:'';right:30px;@include position(absolute);border:0;border-right: 1px solid $ColorOrange;transform: none;height: 30px;top:14px;background-image: none;}
            }
        }
        &+.accordion-item {border-top:1px solid $ColorOrange;}
        &.active {background: $ColorLightPurple;border:1px solid $ColorLightPurple;
            h2 {
                .accordion-button {color:$ColorWhite;box-shadow: none;
                    &::before {content:'';right:30px;@include position(absolute);border:0;border-right: 1px solid $ColorWhite;transform: rotate(-45deg);height: 30px;top:24px;width: 30px;}
                    &::after {content:'';right:30px;@include position(absolute);border:0;border-right: 1px solid $ColorWhite;transform: rotate(45deg);height: 30px;top:4px;width: 30px;background-image: none;}
                }
            }
            .accordion-collapse {
                .accordion-body {color:$ColorWhite;}
            }
        }
    }
}
.inner-pg {padding-top:7rem;text-align:center;padding-bottom:5rem;
    h1 {font-size: $HeadingH2;font-weight: 800;color:$ColorWhite;text-align: center;}
    h3 {font-size: $HeadingH3;font-weight: 800;color:$ColorOrange;text-align: center;}
}
.contact_sec {
    max-width: 618px;margin:0 auto;@include position(relative);
    .form {width:100%;max-width: 100%;margin-bottom: 3rem;}
    h2 {font-size: $HeadingH4;color:$ColorOrange;font-weight: 700;}
    address {color:$ColorWhite;text-decoration: none;font-size: $HeadingH5;}
    ul {margin:0;padding:0;list-style-type: none;@include flexbox();justify-content: center;
        li {padding:0 10px;
            a {color:$ColorWhite;text-decoration: none;font-size: $HeadingH5;}
        }
    }
}

.approvedloanoffer {background: $ColorWhite;text-align:left;color:$ColorBlack;
    .container {
        background: $ColorWhite;width:900px;margin:0 auto;
        h6 {color:$ColorPurple}
        h4 {font-size: $HeadingH5;font-weight: 700;color:$ColorPurple}
        .tbl-style {border:1px solid $ColorPurple;
            tr {
                &:last-child {
                    td {border-bottom: 0;}
                }
            }
             td {background: rgba(22,0,65,0.05);color:#050505;border-bottom:1px solid rgba(22,0,65,0.05);padding:15px 20px;
                &:first-child {background: rgba(230,228,234,1);color:$ColorPurple;width: 175px;}
                label {font-weight: 600;}
             }   
        }
        .tbl-style2 {
            thead {background: rgba(230,228,234,1);color:$ColorPurple;text-align: center}
            td {background: rgba(22,0,65,0.05);color:#050505;text-align: center;}
        }
    }
}
.provision_letter {padding-top:5rem;text-align:left;padding-bottom:0;
    .whiteBg {background: $ColorWhite;
        .container {width: 900px;margin:0 auto;
            h4 {font-weight: 700;font-size: $HeadingH5;}
            ol {padding-left: 20px;
                li {margin-bottom: 1rem;}
            }
            .tbl-style3 {border:1px solid $ColorPurple;
                thead {background: $ColorPurple;color:$ColorWhite;text-align: center}
                td {background: $ColorWhite;color:#050505;text-align: center;padding:15px 20px;
                    &:first-child {width:200px}
                }
                &.typ2 {border:1px solid $ColorPurple;
                    tr {
                        &:last-child {
                            td {border-bottom: 0;}
                        }
                    }
                    td {background: $ColorWhite;border-bottom:1px solid rgba(22,0,65,0.30);
                        &:nth-child(2) {background: rgba(22,0,65,0.05);}
                        &:nth-child(3) {background: rgba(22,0,65,0.10);}
                        &:nth-child(4) {background: rgba(22,0,65,0.05);}
                        &:nth-child(5) {background: rgba(22,0,65,0.10);}
                    }
                }
            }
            
        }
    }
    .btn {width: 350px;height:50px;line-height: 25px;font-size: $HeadingH5;font-weight: 600;}
}

// .navbar-toggler-icon {    background: #fff;
//     background-size: 100%;
//     border: 0;
//     box-shadow: none;
//     border-radius: 5px;padding:5px;position: relative;
//     & span {width:100%;height:2px; background: #000;display: inline-block;margin-top: -10px;}
//     :nth-child(1) {position: absolute;top:0;margin-top:5px;}
//     :nth-child(3) {position: absolute;bottom:0;}
// }

.register.typ2 .container .captions h2 {
    font-size: 2.5rem;
    font-weight: 800;
    color: #FC7124;
    text-align: center;
}
.register.typ2 .container .captions p {text-align: center;}

.desktop-purpose {display: inline-block;}
.mobile-purpose {display: none;}
footer .footer .footer_logos figure {margin:0;width: 360px;padding-right: 10px;
    img {width: 100%;height: auto;}
}
footer .footer .footer_logos {width: 312px;}